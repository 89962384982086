import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MultiLanguageInput from "@common/src/common/MultiLanguageInput/MultiLanguageInput";
import { updateDeliveryConfig, getDeliveryConfig, deleteDeliveryConfig } from "@common/src/service/firebase/deliveryConfig";
import { useSelector } from "react-redux";
import ButtonWithLoader from "@pulse/components/common/buttons/button";
import { useToast } from "@common/src/common/Toast/ToastProvider";

const DeliveryConfig = () => {
  const { user, selectedStore } = useSelector((state) => state.auth);
  const [initialValues, setInitialValues] = useState({ deliveryConfig: { en: "", ar: "" } });
  const [isInitialCreate, setIsInitialCreate] = useState(false); // Track if it's the first creation
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  // Fetch existing delivery config on component mount or when selectedStore changes
  useEffect(() => {
    const fetchDeliveryConfig = async () => {
      if (!selectedStore?.companyId || !selectedStore?.value) {
        // If selectedStore is invalid, reset the form to default values
        setInitialValues({ deliveryConfig: { en: "", ar: "" } });
        setIsInitialCreate(true);
        return;
      }

      try {
        const data = await getDeliveryConfig(selectedStore.companyId, selectedStore.value);
        if (data) {
          setInitialValues({ deliveryConfig: data.expectText || { en: "", ar: "" } });
          setIsInitialCreate(false); // Data exists, not the first creation
        } else {
          setInitialValues({ deliveryConfig: { en: "", ar: "" } });
          setIsInitialCreate(true); // No data, this is the first creation
        }
      } catch (error) {
        console.error("Error fetching delivery config:", error);
      }
    };

    fetchDeliveryConfig();
  }, [selectedStore]);

  const validationSchema = Yup.object({
    deliveryConfig: Yup.object({
      en: Yup.string().required("English delivery config is required"),
      ar: Yup.string().required("Arabic delivery config is required"),
    }),
  });

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    const metadata = {
      updatedAt: new Date(),
      ...(isInitialCreate && { createdAt: new Date() }), // Add createdAt only for the first creation
      createdBy: user?.createdBy,
    };

    const dataToSubmit = {
      expectText: values.deliveryConfig,
      ...metadata,
    };

    try {
      await updateDeliveryConfig(selectedStore?.companyId, selectedStore?.value, dataToSubmit);
      setIsInitialCreate(false); // After the first creation, set to false
      toast.success("Delivery Config updated successfully!");
    } catch (error) {
      toast.error(error.message || "Error updating delivery config");
    }
    finally{
      setTimeout(() => {
        setIsSubmitting(false);
      }, 1000);
    }
  };

  const handleReset = async (resetForm) => {
    try {
      await deleteDeliveryConfig(selectedStore?.companyId, selectedStore?.value);
      setIsInitialCreate(true);
      resetForm({ values: { deliveryConfig: { en: "", ar: "" } } });

      toast.success("Delivery Config reset successfully!");
    } catch (error) {
      toast.error(error.message || "Error resetting delivery config");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-8">
      <div className="w-full max-w-3xl bg-white rounded-lg shadow-md p-6">
        <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Delivery Update</h1>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, touched, resetForm }) => (
            <Form>
              <MultiLanguageInput
                type="input"
                label="Delivery Config"
                id="delivery-config"
                name="deliveryConfig"
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                values={values}
                required
              />
              <div className="flex justify-end mt-6 space-x-4">
                <ButtonWithLoader
                  type="button"
                  onClick={() => handleReset(resetForm)}
                  className="px-6 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
                  disabled={values.deliveryConfig.en === "" && values.deliveryConfig.ar === ""}
                >
                  Reset
                </ButtonWithLoader>
                <ButtonWithLoader
                  type="submit"
                  isLoading={isSubmitting}
                  className="px-6 py-2 bg-primary text-white rounded-md hover:bg-primary/80"
                >
                  Save
                </ButtonWithLoader>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default DeliveryConfig;