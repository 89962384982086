import React from 'react';
import PropTypes from 'prop-types';

const ButtonWithLoader = ({ isLoading, children, onClick, disabled, className, type='button', ...props }) => {
  return (
    <button
      className={` ${className}`}
      onClick={onClick}
      disabled={disabled || isLoading}
      type={type}
      {...props}
    >
      {isLoading ? (
        <>
          <span className="me-2">Loading</span>
          <span className="loading">
            <i className="ri-loader-2-fill text-[1rem] animate-spin"></i>
          </span>
        </>
      ) : (
        children
      )}
    </button>
  );
};

ButtonWithLoader.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
};

ButtonWithLoader.defaultProps = {
  isLoading: false,
  disabled: false,
  className: '',
  type: 'button',
};

export default ButtonWithLoader;

