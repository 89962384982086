import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ORDER_DETAILS } from '@common/src/lib/graphql/query/orders';
import LoaderSm from '@common/src/common/loader/loader-sm';
import EmptyData from '@common/src/common/Table/emptyData';
import { useLocation } from 'react-router-dom';
import { getOrderStatusColor, ORDER_PAYMENT_STATUS, ORDER_STATUS_LIST, SERVICES, ORDER_STATUS_DELIVERY_OPTIONS, ORDER_STATUS_TAKE_AWAY_OPTIONS, ORDER_PAYMENT_STATUS_COD, PROCESS } from './tabContents/common';
import SelectBox from '@common/src/common/selectbox/selectbox';
import Loader from '@common/src/common/loader/loader';
import ConfirmModal from '@common/src/common/modal/confirmModal';
import RefundModal from './refundModal';
import { initiateRefund, updateOrder } from '@common/src/service/cloud/order';
import { useToast } from '@common/src/common/Toast/ToastProvider';
import { getOrderHistory, getPaymentHistory } from '@common/src/service/firebase/order';
import AvatarImage from '../../components/common/ImageHolders/avatarImage';
import { formatDateTime, hasPermission } from '@common/src/common/helper';
import { INNER_PERMISSION } from '@common/src/constants/constants';
import { useSelector } from 'react-redux';
import PaymentInfo from './paymentInfo';

const OrderDetails = () => {
  const [orderDetails, setOrderDetails] = useState(null);
  const [orderHistory, setOrderHistory] = useState([]);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [paymentLoader, setPaymentLoader] = useState(true);
  const [orderLoader, setOrderLoader] = useState(true);
  const [status, setStatus] = useState({});
  const [confirm, setConfirm] = useState({ show: false, type: '' });
  const [refundModal, setRefundModal] = useState(false);
  const [modalLoaders, setModalLoaders] = useState({ confirm: false, refund: false });
  const [isRetry, setIsRetry] = useState({ show: false, type: "" })
  const [statusOptions, setStatusOptions] = useState([])
  const location = useLocation();
  const orderId = new URLSearchParams(location.search).get('orderId');
  const toast = useToast();
  const [reason, setReason] = useState("")
  const { permissions } = useSelector((state) => state.auth)

  const { data, loading, error, refetch } = useQuery(GET_ORDER_DETAILS, {
    variables: { id: orderId },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    fetchOrderHistory()
    fetchPaymentHistory()
    if (!loading && !error) {
      setOrderDetails(data.orderQuery || {});
    }
  }, [data, loading, error]);

  // Set the status options for the order based on its service.
  useEffect(() => {
    const isDeliveryService = [SERVICES.DELIVERY, SERVICES.GLOBAL_DELIVERY].includes(orderDetails?.service);
    let options = isDeliveryService ? ORDER_STATUS_DELIVERY_OPTIONS : ORDER_STATUS_TAKE_AWAY_OPTIONS;
    // Filter out the 'CANCELLED' option if the user lacks the necessary permissions
    if (!hasPermission(permissions, INNER_PERMISSION.ORDER_STATUS_CANCEL)) {
      options = options.filter(({ value }) => value !== ORDER_STATUS_LIST.CANCELLED);
    }

    // Override options to only include 'DECLINED' if the order is in PROCESS status
    if (orderDetails?.orderStatus === PROCESS) {
      options = [{ value: ORDER_STATUS_LIST.DECLINED, label: ORDER_STATUS_LIST.DECLINED }];
    }
    setStatusOptions(options)
  }, [orderDetails]);

  // Disable all options except for cancelling if the order is completed, delivered, or cancelled.
  useEffect(() => {
    if ([ORDER_STATUS_LIST.COMPLETED, ORDER_STATUS_LIST.DELIVERED, ORDER_STATUS_LIST.CANCELLED].includes(orderDetails?.orderStatus)) {
      setStatusOptions(prevOptions =>
        prevOptions.map(option => ({
          ...option,
          isDisabled: option.value !== ORDER_STATUS_LIST.CANCELLED
        }))
      );
    }
  }, [orderDetails]);

  const onClose = () => {
    setModalLoaders({ confirm: false, refund: false })
    setConfirm({ show: false, type: '' })
    setRefundModal(false);
    setReason('')
    setIsRetry({ show: false, type: "" })
  }
  const fetchOrderHistory = async () => {
    try {
      setOrderLoader(true)
      let orderHistoryArr = await getOrderHistory(orderId)
      setOrderHistory(orderHistoryArr)
      setOrderLoader(false)
    } catch (error) {
      setOrderLoader(false)
      console.error('Error retrieving order history:', error);
      return [];
    }
  };
  const fetchPaymentHistory = async () => {
    try {
      setPaymentLoader(true)
      let paymentHistoryArr = await getPaymentHistory(orderId)
      setPaymentHistory(paymentHistoryArr)
      setPaymentLoader(false)
    } catch (error) {
      setPaymentLoader(false)
      console.error('Error retrieving order history:', error);
      return [];
    }
  };
  const onConfirm = () => {

    if (status?.value === ORDER_PAYMENT_STATUS[0].value) {
      triggerUpdateWithRefund()
      return;
    }
    if (status?.value === ORDER_STATUS_LIST.CANCELLED && orderDetails.paymentMode !== ORDER_STATUS_LIST.COD) {
      if (orderDetails?.orderStatus === ORDER_PAYMENT_STATUS[1].value) {
        toast.error("This order has already been refunded.");
        return
      }
      setConfirm({ show: false, type: '' })
      setModalLoaders({ ...modalLoaders, confirm: false })
      setRefundModal(true)
    } else {
      setModalLoaders({ ...modalLoaders, confirm: true })
      triggerUpdateStatus();
    }
  }
  const triggerUpdateStatus = async () => {
    try {
      let updateArr = {
        id: orderDetails?.id,
        orderId: orderId,
        customerId: orderDetails?.user?.id,
        reason
      }
      if (status.value === ORDER_PAYMENT_STATUS_COD[0].value) {
        updateArr.paymentStatus = ORDER_PAYMENT_STATUS_COD[0].value
      } else {
        updateArr.orderStatus = status.value
      }
      await updateOrder(updateArr);
      onClose();
      refetch({ variables: { id: orderId } })
      toast.success('Order status updated successfully');
    } catch (error) {
      onClose();
      console.error('Error updating order status:', error);
    }
  }
  const triggerUpdateWithRefund = async () => {
    try {
      if (orderDetails?.orderStatus !== ORDER_PAYMENT_STATUS[0].value && (
        orderDetails?.paymentStatus !== ORDER_STATUS_LIST.PAID
      )) {
        toast.error('You can not refund orders that are not paid.')
        return
      }
      setModalLoaders({ ...modalLoaders, refund: true })
      const result = await initiateRefund({
        id: orderDetails?.id,
        orderId: orderId,
        orderStatus: status.value,
        customerId: orderDetails?.user?.id,
        reason
      });
      console.log('REFUND RESULT :', result);
      toast.success('Refund initiated successfully');
      onClose();
      refetch({ variables: { id: orderId } });
    } catch (error) {
      onClose();
      refetch({ variables: { id: orderId } });
      console.error('Error in intiate refund:', error);
      toast.error('Unable to initiate the refund. Please try again.');
    }
  }
  const triggerUpdateWithOutRefund = () => {
    setModalLoaders({ ...modalLoaders, confirm: true })
    triggerUpdateStatus()
  }

  let timeZone = orderDetails?.meta?.config?.country?.timezone
  if (loading) return <Loader />;


  let currency = orderDetails?.meta?.config?.currency?.currencyCode
  return (
    <div className="order-details">
      <ConfirmModal
        title="Are you sure?"
        message={
          `Do you want to change the ${confirm.type === 'order'
            ? `order status from ${orderDetails?.orderStatus}`
            : 'payment status'} to ${status.label}?`
        }
        btnTxt="Confirm"
        isOpen={confirm}
        loading={modalLoaders.confirm}
        setIsOpen={setConfirm}
        onClose={onClose}
        action={() => onConfirm()}
        inputBox={status.label === ORDER_STATUS_LIST.CANCELLED && orderDetails.paymentMode === ORDER_STATUS_LIST.COD}
        inputLabel={"Reason"}
        setInputText={setReason}
        inputText={reason}
      />
      <RefundModal
        isOpen={refundModal}
        setIsOpen={setRefundModal}
        loading={{ confirm: modalLoaders.confirm, refund: modalLoaders.refund }}
        message={`Choose the cancellation refund method for order ${orderDetails?.orderNumber}.`}
        action={() => triggerUpdateWithOutRefund()}
        refundAction={() => triggerUpdateWithRefund()}
        reason={reason}
        onClose={onClose}
        orderStatus={orderDetails?.orderStatus}
        setReason={setReason}
      />
      {orderDetails ? (
        <div>
          {orderDetails && (
            <div className="mt-6 min-h-[calc(100vh-150px)]">
              <div className="box">
                <div className='box-body flex justify-between items-center'>
                  <div>
                    <div className="flex items-center">
                      <h2 className="mr-4">Order : {orderDetails.orderNumber}</h2>
                      <h2>
                        <span className={`badge text-lg px-4 py-2 ${getOrderStatusColor(orderDetails.orderStatus)}`}>
                          {ORDER_STATUS_LIST[orderDetails.orderStatus]}
                        </span>
                      </h2>
                    </div>
                    <span className="badge text-lg px-4 py-2 bg-danger text-white">
                      Time Zone: {currency === 'INR' && 'IST'}
                      {currency === 'AED' && 'GMT'}
                    </span>
                  </div>
                  {hasPermission(permissions, INNER_PERMISSION.ORDER_STATUS_CHANGE) &&
                  orderDetails.orderStatus !== ORDER_STATUS_LIST.DECLINED &&
                    orderDetails.orderStatus !== ORDER_STATUS_LIST.FAILED && (
                      <SelectBox
                        name="orderStatus"
                        label="Order Status"
                        isDisabled={[ORDER_PAYMENT_STATUS[0].value, ORDER_PAYMENT_STATUS[1].value, ORDER_STATUS_LIST.CANCELLED].includes(orderDetails.orderStatus)}
                        value={
                          statusOptions.find((option) => option.value === orderDetails.orderStatus)
                        }
                        onChange={(e) => {
                          setStatus(e);
                          if (orderDetails.orderStatus === ORDER_STATUS_LIST.CANCELLED && orderDetails.paymentMode !== ORDER_STATUS_LIST.COD) {
                            setRefundModal(true);
                          } else {
                            setConfirm({ show: true, type: 'order' })
                          }
                        }}
                        className='w-[200px]'
                        options={statusOptions}
                      />
                    )}

                </div>
              </div>

              <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div>
                  <div className='box'>
                    <div className="box-body">
                      <h5 className='font-bold'>Order Summary</h5>
                      <ul className="p-2 mt-6 grid grid-cols-1 gap-6">
                        {orderDetails?.products?.map((product) => {
                          return (
                            <li key={product.id} className="flex flex-col mt-2 rounded p-4 bg-gray-100">
                              <div className="flex items-center  pb-2">
                                <AvatarImage src={product.product?.image?.[0]} />
                                <div className='ml-4'>
                                  <p className="text-lg font-bold">{product?.product?.name?.en}</p>
                                  <p className="text-sm break-all w-1/2">{product?.quantity} x {product?.product?.price}</p>
                                </div>
                              </div>
                              <div className="flex items-center border-t space-x-2 mt-4 w-full">
                                <ul className='w-full'>
                                  {product.modifierGroups?.map((group) => {
                                    return (
                                      <li key={group.id} className='mt-4 w-full'>
                                        <div className='flex items-center'>
                                          <i className="ri-checkbox-circle-line mr-2 text-green text-xl" />
                                          <span className='font-bold text-sm break-all w-1/2'>{group?.groupName?.en || group?.groupName?.ar}</span>
                                        </div>
                                        <div className='mt-2 w-full'>
                                          {group.modifiers.length && (
                                            <table className='w-full border border-gray-300'>
                                              <thead>
                                                <tr className='bg-gray-200 border-b border-gray-300'>
                                                  <th className='border-r border-gray-300 text-center p-1'>Modifier</th>
                                                  <th className='border-r border-gray-300 text-center p-1'>Quantity</th>
                                                  <th className='border-r border-gray-300 text-center p-1'>Add On Price/Qty</th>
                                                  <th className='border-r border-gray-300 text-center p-1'>Amount</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {group.modifiers.map((modifier) => {
                                                  if (modifier?.quantity > 0) {
                                                    return (
                                                      <tr key={modifier?.modifier?.id} className='border-b border-gray-300'>
                                                        <td className='flex items-center border-r border-gray-300 text-center p-1'>
                                                          {modifier?.modifier?.image && <img className='w-10 h-10 rounded-full mr-2' src={modifier?.modifier?.image} alt={'mod-image'} />}
                                                          <span>{modifier?.modifier?.modItemName?.en || modifier?.modifier?.modItemName?.ar}</span>
                                                        </td>
                                                        <td className='border-r border-gray-300 text-center p-1'>{modifier?.quantity * product?.quantity}</td>
                                                        <td className='border-r border-gray-300 text-center p-1'>{modifier?.unitPrice}</td>
                                                        <td className='border-r border-gray-300 text-center p-1'>{modifier?.amount * product?.quantity}</td>
                                                      </tr>
                                                    )
                                                  }
                                                })}
                                              </tbody>
                                            </table>
                                          )}
                                        </div>
                                      </li>
                                    )
                                  })}
                                </ul>
                              </div>
                              <div className='flex items-start mt-4 text-sm break-all w-1/2 font-bold'>
                                <i className="ri-file-info-line mr-2 text-xl" />
                                <p className='flex items-center'>Cooking Instructions: {product.remark ? product.remark : 'N/A'}</p>
                              </div>
                              <p className='text-right mt-4'>Item Total: {currency} {parseFloat(product.amount).toFixed(2)}</p>
                            </li>
                          )
                        })}
                      </ul>
                      <div className='p-4 mt-6 rounded'>
                        <table className="w-full">
                          <tbody>
                            <tr>
                              <th></th>
                              <th></th>
                            </tr>
                            <tr>
                              <td className="text-left text-lg">Delivery Charge</td>
                              <td className="text-right text-lg">{currency} {orderDetails.deliveryCharge}</td>
                            </tr>
                            <tr>
                              <td className="text-left text-lg">Discount</td>
                              <td className="text-right text-lg">{currency} {orderDetails.discountedPrice}</td>
                            </tr>
                            <tr>
                              <td className="text-left text-lg">BiteCoin Redeem</td>
                              <td className="text-right text-lg">{currency} {Number(orderDetails.loyaltyPoints.usedCoins.value).toFixed(2)}</td>
                            </tr>
                            <tr>
                              <td className="text-left text-lg">Subtotal</td>
                              <td className="text-right text-lg">{currency} {orderDetails.subtotal}</td>
                            </tr>
                            <tr>
                              <td className="text-left text-lg">Tax</td>
                              <td className="text-right text-lg">{currency} {orderDetails.taxTotal}</td>
                            </tr>
                            <tr>
                              <td className="text-left text-lg">Total</td>
                              <td className="text-right text-lg">{currency} {orderDetails.total}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className='box'>
                    <div className="box-body">
                      <h5 className='font-bold'>Order Details</h5>
                      <table className="w-full mt-2">
                        <tbody className="bg-transparent">
                          <tr>
                            <td className="text-left text-sm break-all w-1/2 "> <h5 className="text-primary">{orderDetails.service.replace(/_/g, ' ')}</h5></td>
                            <td className="text-right text-sm break-all w-1/2"></td>
                          </tr>
                          {(orderDetails.service === SERVICES.DELIVERY || orderDetails.service === SERVICES.GLOBAL_DELIVERY) && (
                            <>
                              <tr>
                                <td className="text-left text-lg font-bold">Delivery Address</td>
                                <td className="text-left text-sm break-all w-1/2"></td>
                              </tr>
                              <tr>
                                <td className="text-left text-sm break-all w-1/2">{orderDetails?.address?.formatted}</td>
                              </tr>
                              <tr>
                                <td className="text-left text-sm break-all w-1/2">{orderDetails?.address?.houseNumber}</td>
                              </tr>
                              <tr>
                                <td className="text-left text-sm break-all w-1/2">{orderDetails?.address?.apartment}</td>
                              </tr>
                              <tr>
                                <td className="text-left text-sm break-all w-1/2">{orderDetails?.address?.direction}</td>
                              </tr>
                              <tr>
                                <td className="text-left text-sm break-all w-1/2">{orderDetails?.address?.phoneCode} {orderDetails?.address?.phone}</td>
                              </tr>
                            </>
                          )}
                          {(orderDetails.service === SERVICES.TAKEAWAY || orderDetails.service === SERVICES.GLOBAL_TAKEAWAY || orderDetails.service === SERVICES.DINE_IN) && (
                            <>
                              <tr>
                                <td className="text-left text-sm break-all w-1/2 ">Store Details</td>
                                <td className="text-right text-sm break-all w-1/2">{orderDetails?.store?.address?.address?.en}</td>
                              </tr>
                              <tr>
                                <td className="text-left text-sm break-all w-1/2 ">Outlet Code </td>
                                <td className="text-right text-sm break-all w-1/2"><strong></strong>{orderDetails?.outletCode.replace(/"/g, '')}</td>
                              </tr>
                            </>
                          )}
                          {orderDetails?.remarks && (
                            <tr>
                              <td className="text-left text-sm break-all w-1/2 font-bold">Delivery Instructions</td>
                              <td className="text-right text-sm break-all w-1/2">{orderDetails?.remarks}</td>
                            </tr>
                          )}
                          {orderDetails.service === SERVICES.DINE_IN &&
                            (
                              <tr>
                                <td className="text-left text-lg break-all w-1/2">Dine In Time</td>
                                <td className="text-right text-lg break-all w-1/2">{orderDetails?.scheduleTime}</td>
                              </tr>
                            )
                          }
                          {(orderDetails.service === SERVICES.TAKEAWAY || orderDetails.service === SERVICES.GLOBAL_TAKEAWAY) && (

                            <tr>
                              <td className="text-left text-lg break-all w-1/2 ">Take Away Time</td>
                              <td className="text-right text-lg break-all w-1/2">{orderDetails?.scheduleTime}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className='box'>
                    <div className='box-body'>
                      <h5 className='font-bold'>Customer Information</h5>
                      <div className="mt-6">
                        <p className='text-sm break-all w-1/2'>{orderDetails.user?.firstName} {orderDetails.user?.lastName}</p>
                        <p className='text-sm break-all w-1/2'>{orderDetails.user?.email}</p>
                        <p className='text-sm break-all w-1/2'>+{orderDetails.user?.phoneCode} {orderDetails.user?.phoneNumber}</p>
                        <p className='text-sm break-all w-1/2'>{orderDetails?.address?.formatted}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  {[
                    { title: 'Order History', data: orderHistory, loader: orderLoader, type: 'ORDER' },
                    { title: 'Payment History', data: paymentHistory, loader: paymentLoader, type: 'PAYMENT' },
                  ].map((section) => (
                    <div key={section.title} className="box mb-6">
                      <div className="box-body">
                        <h5 className='font-bold'>{section.title}</h5>
                        <div className="mt-6 ">
                          {section.loader ? <LoaderSm /> : (
                            <>
                              {section.data.length > 0 ? (
                                <>
                                  {section.data.map((item, index) => (
                                    <div key={item.id} className={`mt-4 ${index !== section.data.length - 1 ? 'border-b' : ''} border-solid border-gray-300`}>
                                      <div className="!pb-0 !pt-0">
                                        <div className="flex items-center mb-2">
                                          <i className="ri-calendar-event-line text-success text-2xl mr-2"></i>
                                          <div className="flex-1">
                                            <h6>{formatDateTime(item.createdAt, timeZone, true)}</h6>
                                          </div>
                                          <div>
                                            <h6>
                                              <span className={`badge ${getOrderStatusColor(ORDER_STATUS_LIST[item.status])}`}>
                                                {ORDER_STATUS_LIST[item.status]}
                                              </span>
                                            </h6>
                                          </div>
                                        </div>
                                        <p>{item?.reason}</p>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <div className='flex flex-col items-center'>
                                  <p className="text-center">No details found</p>
                                  <button
                                    className="bg-primary text-white rounded px-4 py-2 mt-4"
                                    onClick={() => section.type === 'ORDER' ? fetchOrderHistory() : fetchPaymentHistory()}
                                  >
                                    Refresh
                                  </button>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                  <PaymentInfo
                    orderDetails={orderDetails}
                    modalLoaders={modalLoaders}
                    refundAction={triggerUpdateWithRefund}
                    isRetry={isRetry}
                    setIsRetry={setIsRetry}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <EmptyData icon="shopping-cart-line" text="Order details not found" />
      )}
    </div>
  );
};

export default OrderDetails;
