import React, { useState, useEffect } from 'react';
import OrderTable from './orderTable';
import { ORDER_STATUS_LIST } from './common';
import { useQuery } from '@apollo/client';
import Loader from '@common/src/common/loader/loader';
import PropTypes from 'prop-types';
import { ALL_OPTION } from '@pulse/constants/constants';
import { GET_ALL_ORDERS } from '@common/src/lib/graphql/query/orders';
import { calcOffset } from '@common/src/common/helper';
import { useToast } from '@common/src/common/Toast/ToastProvider';
const DisputeOrders = ({ store }) => {
    const [selectedStatus, setSelectedStatus] = useState(ALL_OPTION.status);
    const [disputeOrders, setDisputeOrders] = useState([]);
    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const toast = useToast()
    const [pagination, setPagination] = useState({
        limit: 100,
        offset: 0,
        currentPage: 1,
        totalPages: 0,
        totalRecords: 0
    })

    const { data, loading, refetch, error } = useQuery(GET_ALL_ORDERS, {
        variables: {
            limit: pagination.limit,
            offset: calcOffset(searchQuery, pagination.currentPage, pagination.limit, pagination.totalRecords),
            search: searchQuery || undefined,
            filters: {
                orderStatus: selectedStatus?.value === 'all' ? [ORDER_STATUS_LIST.FAILED, ORDER_STATUS_LIST.CANCELLED, ORDER_STATUS_LIST.DECLINED] : [selectedStatus?.value],
                storeId: store?.value ? store.value.toString() : undefined,
                startDate: filterStartDate || undefined,
                endDate: filterEndDate || undefined
            }
        },
        fetchPolicy: 'no-cache'
    });
    useEffect(() => {
        if (data && !loading && !error) {
            setDisputeOrders(data.orderListQuery.rows);
            setPagination((prevPagination) => ({
                ...prevPagination,
                totalPages: data.orderListQuery.totalPages,
            }))
        } else {
            setDisputeOrders([])
        }
    }, [data, loading, error]);
    const handleSearchChange = (newSearchItem) => {
        setSearchQuery(newSearchItem);
    };
    useEffect(() => {
        refetch({
            variables: {
                limit: pagination.limit,
                offset: calcOffset(searchQuery, pagination.currentPage, pagination.limit, pagination.totalRecords),
                ...(store && { storeId: store.value.toString() }),
                ...(searchQuery && { search: searchQuery }),
            },
        });
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1
        }))
    }, [store, searchQuery]);
    const onAllFilter = (selectedStartDate, selectedEndDate, selectedStatus) => {
        
        if (selectedStartDate && !selectedEndDate) {
            toast.error('Please select the To date.')
            return;
        } else if (!selectedStartDate && selectedEndDate) {
            toast.error('Please select the From date.')
            return;
        }
        // Clear all filters
        setFilterStartDate('');
        setFilterEndDate('');
        setSelectedStatus(ALL_OPTION.status);

        if (selectedStatus?.value) {
            setSelectedStatus(selectedStatus)
        }
        if (selectedStartDate !== '') {
            setFilterStartDate(selectedStartDate)
        }
        if (selectedEndDate !== '') {
            setFilterEndDate(selectedEndDate)
        }
    }
    if (loading) return <Loader />
    return (
        <div className="mt-6 table-responsive">
            <OrderTable
                orders={disputeOrders}
                onFilter={onAllFilter}
                searchQuery={handleSearchChange}
                searchData={searchQuery}
                setSelectedStatus={setSelectedStatus}
                selectedFilterStatus={selectedStatus}
                disputeOrder={true}
                filterStartDate={filterStartDate}
                filterEndDate={filterEndDate}
                pagination={pagination}
                setPagination={setPagination}
            />
        </div>
    )
};

DisputeOrders.propTypes = {
    store: PropTypes.object,
}
export default DisputeOrders;
