import { db } from "@pulse/firebase/firebaseapi";
import { deleteDoc, doc, getDoc, setDoc } from "firebase/firestore";

export const updateDeliveryConfig = async (companyId, storeId, deliveryConfig) => {
  try {
    console.log("Updating delivery config...");
    const path = `/companies/${companyId}/stores/${storeId}/liveUpdates`;
    const docRef = doc(db, path, "deliveryConfig");
    await setDoc(docRef, deliveryConfig, { merge: true });
    console.log("Delivery Config updated successfully!");
  } catch (error) {
    console.error("Error updating delivery config:", error);
  }
};

export const deleteDeliveryConfig = async (companyId, storeId) => {
  try {
    console.log("Deleting delivery config...");
    const path = `/companies/${companyId}/stores/${storeId}/liveUpdates`;
    const docRef = doc(db, path, "deliveryConfig");
    await deleteDoc(docRef);
    console.log("Delivery Config deleted successfully!");
  } catch (error) {
    console.error("Error deleting delivery config:", error);
  }
};

export const getDeliveryConfig = async (companyId, storeId) => {
  try {
    const path = `/companies/${companyId}/stores/${storeId}/liveUpdates/deliveryConfig`;
    const docRef = doc(db, path);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error getting delivery config:", error);
  }
};